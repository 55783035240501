import { FC } from 'react'

import '../style.less'

export const AgreementSectionList: FC = ({ children }) => {
    return (
        <ol className="section-list">
            <li>{children}</li>
        </ol>
    )
}
