import { FC } from 'react'

import '../style.less'

interface IComponentProps {
    title: string
    headerWidth?: number
}

export const TwoColumnRow: FC<IComponentProps> = ({ children, title, headerWidth = 43 }) => {
    return (
        <p className="two-column-row">
            <b style={{ width: `${headerWidth}%`, textAlign: 'right' }}>{title}</b>
            <span>{children}</span>
        </p>
    )
}
