import { Box, ConditionsModal, LinkDocument } from '@dltru/dfa-ui'
import { FC } from 'react'

import { AgreementSection } from './components/AgreementSection'
import { AgreementSectionList } from './components/AgreementSectionList'
import { AgreementSectionParagraph } from './components/AgreementSectionParagraph'
import { SubParagraphList } from './components/SubParagraphList'
import { TwoColumnRow } from './components/TwoColumnRow'
import './style.less'
import { IAgreementModal } from './types'

export const UserAgreement: FC<IAgreementModal> = ({ ...props }) => {
    return (
        <ConditionsModal
            title={<Box align="center">ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ</Box>}
            width={800}
            {...props}
            text={
                <Box padding={[0, 0, 0, 48]}>
                    <p>
                        Общество с ограниченной ответственностью «Системы распределенного реестра»,
                        с одной стороны, и лицо, присоединившееся к настоящему пользовательскому
                        соглашению, с другой стороны, договорились о нижеследующем:
                    </p>
                    <AgreementSectionList>
                        <AgreementSection title="ТЕРМИНЫ">
                            <AgreementSectionParagraph>
                                В настоящем пользовательском соглашении, если из текста прямо не
                                вытекает иное, следующие термины будут иметь указанные ниже
                                значения:
                                <TwoColumnRow title="«Аутентификационные данные»">
                                    данные, необходимые для Аутентификации и обеспечивающие доступ
                                    Пользователя к ЛК Пользователя
                                </TwoColumnRow>
                                <TwoColumnRow title="«Политика обработки ПД»">
                                    политика в отношении обработки персональных данных, доступная на
                                    Сайте Оператора по адресу: <LinkDocument />.
                                </TwoColumnRow>
                                <TwoColumnRow title="«Правила»">
                                    правила информационной системы ООО «Системы распределенного
                                    реестра», согласованные Банком России в порядке, предусмотренном
                                    законодательством Российской Федерации, доступные на странице в
                                    информационно-телекоммуникационной сети «Интернет» по адресу:{' '}
                                    <LinkDocument />.
                                </TwoColumnRow>
                                <TwoColumnRow title="«Соглашение»">
                                    настоящее пользовательское соглашение.
                                </TwoColumnRow>
                                <TwoColumnRow title="«Стороны»">
                                    Оператор и Заявитель / Пользователь. Термин <b>«Сторона» </b>
                                    означает любую из Сторон.
                                </TwoColumnRow>
                            </AgreementSectionParagraph>
                            <AgreementSectionParagraph>
                                Все термины, встречающиеся в тексте Соглашения с заглавной буквы и
                                содержание которых не раскрыто в Соглашении, применяются в значении,
                                предусмотренном Правилами.
                            </AgreementSectionParagraph>
                            <AgreementSectionParagraph>
                                Названия заголовков (статей) Соглашения предназначены исключительно
                                для удобства пользования текстом Соглашения и буквального
                                юридического значения не имеют.
                            </AgreementSectionParagraph>
                        </AgreementSection>
                        <AgreementSection title="ПОРЯДОК ЗАКЛЮЧЕНИЯ СОГЛАШЕНИЯ">
                            <AgreementSectionParagraph>
                                Текст Соглашения, доступный в регистрационной форме ЛК Пользователя,
                                содержит все существенные условия Соглашения и является предложением
                                Оператора заключить Соглашение с Заявителем, совершившим действия,
                                предусмотренные пунктами 8.2.1 и 8.2.2 Правил.
                            </AgreementSectionParagraph>
                            <AgreementSectionParagraph>
                                В соответствии со статьей 438 Гражданского кодекса Российской
                                Федерации надлежащим акцептом Соглашения считается последовательное
                                осуществление Заявителем следующих действий:
                                <SubParagraphList
                                    elementsArray={[
                                        <span>ознакомление с условиями Соглашения;</span>,
                                        <span>
                                            проставление отметки о согласии, выраженной в форме «✓»,
                                            в специальном поле под заголовком «Согласен с условиями
                                            пользовательского соглашения»;
                                        </span>,
                                        <span>нажатие электронной кнопки «Войти».</span>,
                                    ]}
                                />
                            </AgreementSectionParagraph>
                            <AgreementSectionParagraph>
                                Соглашение может быть акцептовано в любой момент времени до тех пор,
                                пока оно доступно в регистрационной форме ЛК Пользователя.
                            </AgreementSectionParagraph>
                        </AgreementSection>
                        <AgreementSection title="ПРЕДМЕТ СОГЛАШЕНИЯ">
                            <AgreementSectionParagraph>
                                В соответствии с пунктами 8.2.4, 8.2.5, 8.2.8, 8.2.9 и 8.3, 8.4 Правил, заключая Соглашение,
                                Заявитель настоящим в полном объеме присоединяется к Правилам.
                            </AgreementSectionParagraph>
                            <AgreementSectionParagraph>
                                Соглашение применяется к отношениям между Оператором и Пользователем
                                в части неурегулированной Правилами. В случае противоречия между
                                Правилами и Соглашением приоритет имеют Правила.
                            </AgreementSectionParagraph>
                        </AgreementSection>
                        <AgreementSection title="ИЗМЕНЕНИЕ УСЛОВИЙ СОГЛАШЕНИЯ">
                            <AgreementSectionParagraph>
                                Оператор вправе в одностороннем порядке вносить изменения в
                                Соглашение путем:
                                <SubParagraphList
                                    elementsArray={[
                                        <span>
                                            размещения новой редакции Соглашения на Сайте Оператора;
                                            и
                                        </span>,
                                        <span>
                                            публикации на Сайте Оператора информационного сообщения
                                            об изменении Соглашения.
                                        </span>,
                                    ]}
                                />
                            </AgreementSectionParagraph>
                            <AgreementSectionParagraph>
                                Новая редакция Соглашения вступает в силу по истечении 5 (Пяти)
                                Рабочих дней со дня совершения Оператором действий, предусмотренных
                                пунктом 4.1 Соглашения, если иной срок не указан в информационном
                                сообщении, публикуемом в соответствии с пунктом 4.1.2 Соглашения.
                            </AgreementSectionParagraph>
                        </AgreementSection>
                        <AgreementSection title="ПОРЯДОК СОВЕРШЕНИЯ ЮРИДИЧЕСКИХ ЗНАЧИМЫХ ДЕЙСТВИЙ В ИС">
                            <AgreementSectionParagraph>
                                Аутентификационные данные Пользователя признаются Сторонами ключом
                                простой электронной подписи Пользователя в значении, предусмотренном
                                Федеральным законом «Об электронной подписи» от 06.04.2011 N 63-ФЗ.
                            </AgreementSectionParagraph>
                            <AgreementSectionParagraph>
                                Любой документ, направленный Пользователем в ЛК Пользователя,
                                считается подписанным простой электронной подписью Пользователя,
                                указанной в пункте 5.1 Соглашения, а равно любые действия,
                                совершенные в ЛК Пользователя, считаются совершенными Пользователем.
                                Во избежание сомнений Пользователь самостоятельно несет
                                ответственность за все действия, совершенные в ЛК Пользователя, вход
                                в который произведен с использованием Аутентификационных данных.
                            </AgreementSectionParagraph>
                            <AgreementSectionParagraph>
                                Пользователь несет ответственность за безопасность (в том числе
                                устойчивость к угадыванию) выбранных им Аутентификационных данных, а
                                также самостоятельно обеспечивает их конфиденциальность.
                            </AgreementSectionParagraph>
                            <AgreementSectionParagraph>
                                Пользователь обязан незамедлительно уведомить Оператора любым
                                доступным способом:
                                <SubParagraphList
                                    elementsArray={[
                                        <span>
                                            о любом известном Пользователю случае
                                            несанкционированного (неразрешенного Пользователем)
                                            доступа к ЛК Пользователя;
                                        </span>,
                                        <span>
                                            о любом нарушении или подозрении в нарушении
                                            конфиденциальности Аутентификационных данных (в том
                                            числе утрате доступа к Аутентификационным данным).
                                        </span>,
                                    ]}
                                />
                            </AgreementSectionParagraph>
                            <AgreementSectionParagraph>
                                Пользователь обязан самостоятельно осуществлять завершение работы
                                своей пользовательской сессии в ЛК Пользователя посредством нажатия
                                электронной кнопки «Выход» в ЛК Пользователя.
                            </AgreementSectionParagraph>
                        </AgreementSection>
                        <AgreementSection title="ПОРЯДОК НАПРАВЛЕНИЯ ЮРИДИЧЕСКИ ЗНАЧИМЫХ СООБЩЕНИЙ">
                            <AgreementSectionParagraph>
                                Любое уведомление, требование или иное юридически значимое
                                сообщение, направляемое одной Стороной другой Стороне в связи с
                                Соглашением, имеет юридическую силу только в случае, если оно
                                направлено посредством уполномоченного адреса электронной почты, а в
                                случаях, предусмотренных Правилами, посредством ЛК Пользователя.
                            </AgreementSectionParagraph>
                            <AgreementSectionParagraph>
                                Сведения, необходимые для доступа к уполномоченным адресам
                                электронной почты, признаются Сторонами ключом простой электронной
                                подписи в значении, предусмотренном Федеральным законом «Об
                                электронной подписи» от 06.04.2011 N 63-ФЗ. Стороны обязуются
                                сохранять указанные сведения в тайне от третьих лиц, самостоятельно
                                определяя порядок обеспечения конфиденциальности этих сведений.
                            </AgreementSectionParagraph>
                            <AgreementSectionParagraph>
                                Уполномоченными адресами электронной почты Сторон признаются:
                                <SubParagraphList
                                    elementsArray={[
                                        <span>для Оператора: dfa@masterchain.ru;</span>,
                                        <span>
                                            для Заявителя: адрес электронной почты, указанный
                                            Заявителем в регистрационной форме;
                                        </span>,
                                        <span>
                                            для Пользователя: адрес электронной почты, указанный
                                            Пользователем в ЛК Пользователя.
                                        </span>,
                                    ]}
                                />
                            </AgreementSectionParagraph>
                            <AgreementSectionParagraph>
                                Пользователь вправе изменить уполномоченный адрес электронной почты
                                в следующем порядке:
                                <SubParagraphList
                                    elementsArray={[
                                        <span>
                                            Пользователь переходит в форму смены уполномоченного
                                            адреса электронной почты в ЛК Пользователя и указывает в
                                            ней новый уполномоченный адрес электронной почты;
                                        </span>,
                                        <span>
                                            Оператор отправляет код подтверждения смены
                                            уполномоченного адреса электронной почты на адрес
                                            электронной почты, указанный в пункте 6.4.1 Соглашения;
                                        </span>,
                                        <span>
                                            Пользователь вводит код подтверждения смены
                                            уполномоченного адреса электронной почты, направленный в
                                            соответствии с пунктом 6.4.2 Соглашения, в ЛК
                                            Пользователя;
                                        </span>,
                                        <span>
                                            Оператор направляет Пользователю уведомление в ЛК
                                            Пользователя о смене уполномоченного адреса электронной
                                            почты.
                                        </span>,
                                    ]}
                                />
                            </AgreementSectionParagraph>
                            <AgreementSectionParagraph>
                                Уполномоченный адрес электронной почты считается измененным с
                                момента направления Пользователю уведомления, предусмотренного
                                пунктом 6.4.4 Соглашения.
                            </AgreementSectionParagraph>
                        </AgreementSection>
                        <AgreementSection title="ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ">
                            <AgreementSectionParagraph>
                                Персональные данные Пользователя, в том числе представителя
                                Пользователя, обрабатываются Оператором в соответствии с Политикой
                                обработки ПД, локальными нормативными актами Оператора и согласием
                                Пользователя на обработку персональных данных.
                            </AgreementSectionParagraph>
                            <AgreementSectionParagraph>
                                Сайт Оператора может содержать ссылки на другие страницы в
                                информационно-телекоммуникационной сети «Интернет» (сайты третьих
                                лиц). Указанные третьи лица и их контент не проверяются Оператором
                                на соответствие тем или иным требованиям (достоверности, полноты,
                                законности и подобное). Оператор не несет ответственности за любую
                                информацию, материалы, размещенные на сайтах третьих лиц, к которым
                                Пользователь получает доступ с использованием Сайта Оператора, а
                                также за доступность таких сайтов или контента и последствия их
                                использования Пользователем.
                            </AgreementSectionParagraph>
                            <AgreementSectionParagraph>
                                Соглашение прекращается в момент исключения Пользователя из Реестра
                                Пользователей.
                            </AgreementSectionParagraph>
                        </AgreementSection>
                    </AgreementSectionList>
                </Box>
            }
        />
    )
}
