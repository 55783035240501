import { FC } from 'react'

import '../style.less'

export const AgreementSection: FC<{ title?: string }> = ({ children, title }) => {
    return (
        <ol className="numbered-list">
            {title ? (
                <>
                    <b>
                        <h3 className="section-header numbered-section-header">{title}</h3>
                    </b>
                    {children}
                </>
            ) : (
                <div className="numbered-section-body">{children}</div>
            )}
        </ol>
    )
}
