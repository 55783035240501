import { FC, ReactElement } from 'react'

import '../style.less'

export const SubParagraphList: FC<{ elementsArray: Array<ReactElement | string> }> = ({
    elementsArray,
}) => {
    return (
        <ol className="subparagraph-list">
            {elementsArray.map((element) => (
                <li>{element}</li>
            ))}
        </ol>
    )
}
